body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, form, fieldset, input, textarea, p, blockquote, th, td {
    margin: 0;
    padding: 0;
    background-color: #161616
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

fieldset, img {
    border: 0;
}

address, caption, cite, code, dfn, th, var {
    font-style: normal;
    font-weight: normal;
}

caption, th {
    text-align: left;
}

q:before, q:after {
    content: '';
}

abbr, acronym {
    border: 0;
}

@font-face {
    font-family: "Dimini Sans";
    src: url('./fonts/DiminiSans-Light.woff2') format("woff2");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Dimini Sans";
    src: url('./fonts/DiminiSans-Regular.woff2') format("woff2");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Dimini Sans";
    src: url('./fonts/DiminiSans-Medium.woff2') format("woff2");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Dimini Sans";
    src: url('./fonts/DiminiSans-SemiBold.woff2') format("woff2");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Dimini Sans";
    src: url('./fonts/DiminiSans-Bold.woff2') format("woff2");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Dimini Sans";
    src: url('./fonts/DiminiSans-ExtraBold.woff2') format("woff2");
    font-weight: 800;
    font-style: normal;
}

svg {
    vertical-align: middle;
    fill: #fff;
    height: 30vmin;
    width: 30vmin;
    margin-bottom: 0.4em;
    margin-right: 0.4em;
}

#canvas, #text {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
}

#text {
    min-height: 100vh;
    width: 100vw;
    z-index: -1;
    color: #fff;
    opacity: 0.75;
    font-size: 10vmin;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: "Dimini Sans", sans-serif;
}

#text span {
    font-size: 3vmin;
    vertical-align: text-top;
    line-height: 15vmin;
}